import { Link } from '@remix-run/react';
import { $path } from 'remix-routes';

import { PageLoadingBar } from '../../../app/components/PageLoadingBar';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useUGCAnalytics } from '../Game/UGC/utils';
import { NavItem, ScheduleTooltip } from '../Header';
import { HeaderAvatar } from '../HeaderAvatar';
import { LunaParkLogo } from '../icons/LogoIcon';
import { useMyOrganizationFeatureChecker } from '../Organization';

export function PublicHomeLayoutV2(props: {
  secondaryNav?: React.ReactNode;
  children: React.ReactNode;
  backgroundClassName?: string;
}) {
  const { secondaryNav } = props;

  const analytics = useUGCAnalytics();
  const featureChecker = useMyOrganizationFeatureChecker();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');
  const showCustomGameLink =
    ugcEnabled || featureChecker.hasUnlimitedOndGameUGCAccess();

  return (
    <div className='w-full h-full flex flex-col'>
      <PageLoadingBar color='#f11946' />

      <div
        className='
          w-full flex-none h-15 
          bg-black text-white border-secondary border-b 
          flex items-center gap-10 z-40 pl-10 pr-5
        '
      >
        <div className='flex-1 h-full overflow-hidden flex items-center gap-4'>
          <Link to={$path('/home')} className='flex-none'>
            <LunaParkLogo className='w-27 h-7.5' />
          </Link>
        </div>

        <div className='flex-none h-full flex items-center gap-10'>
          {showCustomGameLink && (
            <NavItem
              title='My Custom Games'
              to='/custom-games'
              onClick={() => analytics.trackMyCustomGamesClicked()}
            />
          )}
          <ScheduleTooltip />
          <HeaderAvatar />
        </div>
      </div>

      {secondaryNav}

      <div
        className={`
        w-full flex-1 ${props.backgroundClassName ?? 'bg-modal'} text-white
        overflow-auto scrollbar`}
      >
        {props.children}
      </div>
    </div>
  );
}
